<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.fu_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="6">
                <v-toolbar class="primary"><v-toolbar-title></v-toolbar-title>
                    <v-btn icon dark @click="fload"><v-icon>mdi-file-import</v-icon></v-btn>
                    <v-btn icon dark @click="fvalidate"><v-icon>mdi-check</v-icon></v-btn>
                    <v-btn icon dark @click="fprocess"><v-icon>mdi-cog-refresh</v-icon></v-btn>
                    <v-btn icon dark @click="fpreview"><v-icon>mdi-view-list-outline</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon dark  @click="fcancel"><v-icon>mdi-cog-counterclockwise</v-icon></v-btn>
                </v-toolbar>             
                <v-card class="mt-5" v-if="worksheet">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="worksheet.name" label="Worksheet Name" readonly outlined></v-text-field>
                                <v-text-field v-model="worksheet.process_reference_no" label="Process Reference No" readonly outlined></v-text-field>
                                
                                <v-select
                                    v-model="worksheet.file_upload_template_id"
                                    :items="fuTemplateList"
                                    item-text="fut_name"
                                    item-value="file_upload_template_id"
                                    label="Import Template"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                    :disabled="psList[worksheet.process_status].disabled"
                                ></v-select>
                                Process Status:
                                <v-chip class="ma-2" :color="tlColor[worksheet.process_status].color"  text-color="white">
                                {{worksheet.process_status}}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                </v-card>
                <v-toolbar  dark class="primary mt-5"><v-toolbar-title>Validation Option</v-toolbar-title>
                </v-toolbar>
                <v-card class="mt-1">
                <v-container class="py-0">
                    <v-list flat>
                        <v-list-item v-for="(fuv,index) in fuValidationList" :key="'fuv-index-'+ index">
                            <v-list-item-action>
                                    <v-checkbox v-model="fuValidation" :value="fuv.file_upload_validation_id"></v-checkbox>
                            </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{fuv.fuv_name}}</v-list-item-subtitle>
                                </v-list-item-content>

                        </v-list-item>
                    </v-list>
            </v-container>
                
                </v-card>
            </v-col>
            <!--image-->
            <v-col cols="12" md="6">
                <v-toolbar dark class="warning"><v-toolbar-title>Time Line</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-1">
                    <v-timeline align-top dense>
                        <template v-for="(timeLine,index) in this.worksheet.time_line"
                            >
                        <v-timeline-item :color="tlColor[timeLine.process_status].color" small  :key="'index' + index">
                            <v-row class="pt-1">
                                <v-col cols="4">
                                <div class="text-caption">{{ timeLine.date }}</div>
                                </v-col>
                                <v-col>
                                <strong>{{ timeLine.process_status }}</strong>
                                <div class="text-caption">
                                    {{ timeLine.description }}
                                </div>
                                </v-col>
                            </v-row>
                            </v-timeline-item>     
                        </template>               
                    </v-timeline>                    
                </v-card> 

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApFileUploadService from "@/services/ApFileUploadService"
import ApParameterService from "@/services/ApParameterService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'File Upload',disabled: false,href:'/',},
              {text:'List',disabled: false,href:'/fileupload/list/member_registration',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            fuTemplateList:[],
            fuValidationList:[],
            fuValidation:[],
            routeParam:[],
            worksheet:{"process_status":"loaded"},
            form:{login_id:null,file_upload_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'member_registration',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            tlColor:{
                "new":{"color":"orange"},
                "loaded":{"color":"lime"},
                "validated":{"color":"light-green"},
                "processed":{"color":"green"},
                "cancelled":{"color":"red"},
            },
            psList:{
                "new":{"disabled":false},
                "loaded":{"disabled":false},
                "validated":{"disabled":true},
                "processed":{"disabled":true},
                "cancelled":{"disabled":false},
            }
            

    }
  },
  async created() {
    this.routeParam['file_upload_id'] = this.$route.params.file_upload_id;
    this.routeParam['route_type'] = this.$route.params.route_type;
    this.routeParam['ws_index'] = this.$route.params.ws_index;
    this.getParameter();
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.file_upload_id = this.routeParam.file_upload_id;
        this.form.route_type = this.routeParam.route_type;
        try{
            ApFileUploadService.view_file_upload(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                //console.log(res.data);
                if(this.form.worksheet_list[this.routeParam.ws_index]){
                    this.worksheet = this.form.worksheet_list[this.routeParam.ws_index];
                    if(this.worksheet.process_status == ""){
                        this.worksheet.process_status = "new";
                    }
                    //console.log("worksheet:",this.worksheet)
                }
                this.status_list = res.data.statusList; 
               
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View File Uploads',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        if(this.$refs.mainForm.validate()) {        
            try{
                ApFileUploadService.update_file_upload(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Update File Upload','Success.');
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Update File Upload',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    async getParameter(){
    try{
        let parameter = {login_id:"",param_type:"process-fileupload",route_type:this.routeParam.route_type}
        ApParameterService.get_parameter(parameter).then((res) => {
            this.fuTemplateList = res.data.fuTemplateList;
            this.fuValidationList = res.data.fuValidationList;
            //console.log(res.data);
            
        }).catch((e)=>{
            //console.log(e.response);
            this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
        })
        ;
    } catch(e) {
        //console.log(e);
        //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
    }
    },

    async fload(){
        let request = {};
        request['login_id'] = "";
        request['file_upload_id'] = this.form.file_upload_id;
        request['route_type'] = this.routeParam.route_type;
        request['file_upload_template_id'] = this.worksheet.file_upload_template_id;
        request['file_upload_validation_id'] = [1];
        request['worksheet_index'] = this.routeParam.ws_index;
        if(this.$refs.mainForm.validate()) {        
            try{
                ApFileUploadService.load_file_upload(request).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Worksheet - Load','Success.');
                    this.viewDetail();
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Load',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Load','Unknown Error');
            }
        }

    },    
    async fvalidate(){
        let request = {};
        request['login_id'] = "";
        request['file_upload_id'] = this.form.file_upload_id;
        request['route_type'] = this.routeParam.route_type;
        request['file_upload_template_id'] = this.worksheet.file_upload_template_id;
        request['file_upload_validation_id'] = this.fuValidation;
        request['worksheet_index'] = this.routeParam.ws_index;
        //console.log(request);
           
        if(this.$refs.mainForm.validate()) {        
            try{
                ApFileUploadService.validate_file_upload(request).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Worksheet - Validate','Success.');
                    this.viewDetail();
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Validate',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Validate','Unknown Error');
            }
        }

    },    
    async fprocess(){
        let request = {};
        request['login_id'] = "";
        request['file_upload_id'] = this.form.file_upload_id;
        request['route_type'] = this.routeParam.route_type;
        request['file_upload_template_id'] = this.worksheet.file_upload_template_id;
        request['file_upload_validation_id'] = [1];
        request['worksheet_index'] = this.routeParam.ws_index;
        if(this.$refs.mainForm.validate()) {        
            try{
                ApFileUploadService.process_file_upload(request).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Worksheet - Process','Success.');
                    this.viewDetail();
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Process',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Process','Unknown Error');
            }
        }
    },
    async fpreview(){
        let routeData = this.$router.resolve({ name: 'fileupload-wspreview', 
            params: { 
                file_upload_id: this.form.file_upload_id,
                file_upload_template_id : this.worksheet.file_upload_template_id,
                route_type: this.form.route_type,
                ws_index: this.routeParam['ws_index'],

            } 
        })
        window.open(routeData.href,'_blank')

    },    
    async fcancel(){
        let request = {};
        request['login_id'] = "";
        request['file_upload_id'] = this.form.file_upload_id;
        request['route_type'] = this.routeParam.route_type;
        request['file_upload_template_id'] = this.worksheet.file_upload_template_id;
        request['worksheet_index'] = this.routeParam.ws_index;
        if(this.$refs.mainForm.validate()) {        
            try{
                ApFileUploadService.cancel_file_upload(request).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Worksheet - Cancel','Success.');
                    this.viewDetail();
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Cancel',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet - Process','Unknown Error');
            }
        }        
    },    
  }
}
</script>